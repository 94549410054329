article.orders
  .modal-unavailable-box
    background-color: #fff
    height: 100%
    +left_ltr(0)
    position: fixed
    top: 0
    width: 100%
    z-index: 10

    +lte_ipad
      padding: rem(30px) rem(16px)

    +gte_laptop
      padding: 30px 46px

    .body
      align-items: center
      display: flex
      flex-direction: column
      height: 100%
      justify-content: center
      position: relative

      p
        font-size: rem(14px)

      a.deposit-close,
      a.modal-close
        background-image: url('../images/pages/orders/unavailable_box/x.svg')
        background-repeat: no-repeat
        background-size: contain
        display: inline-block
        height: rem(24px)
        position: absolute
        +right_ltr(0)
        top: 0
        width: rem(24px)

        &:hover
          opacity: 0.8

        &:active
          opacity: 0.9

      .title
        font-size: rem(24px)
        font-weight: bold
        line-height: rem(28px)
        margin-bottom: rem(24px)

  .modal-deposit
    .body
      p
        max-width: 540px
        +text-align_ltr(left)

  .modal-unavailable-box
    .body
      .warning-big-image
        background-image: url('../images/pages/orders/unavailable_box/warning-big-image.svg')
        background-repeat: no-repeat
        background-size: contain
        height: rem(160px)
        margin-bottom: rem(24px)
        width: rem(173px)

      .inner
        text-align: center

        p
          font-size: rem(14px)
          margin-bottom: rem(56px)

      a.goto-storage
        +button_red
        width: 100%
