@use 'sass:math'

@import 'node_modules/css-reset-and-normalize/scss/reset-and-normalize'
@import 'node_modules/css-reset-and-normalize/scss/button-reset'
@import 'node_modules/css-reset-and-normalize/scss/link-reset'

@import ./functions/*
@import ./mixins/responsive
@import ./variables
@import ./fonts

@import ./mixins/*

html
  color: var(--text-base)
  font-size: 16px
  height: 100%
  line-height: 1.3rem

  -webkit-text-size-adjust: 100%
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  // font-feature-settings: 'liga'

  @media screen and (min-width: 340px) and (max-width: 359px)
    font-size: 17px

  @media screen and (min-width: 360px) and (max-width: 389px)
    font-size: 18px

  @media screen and (min-width: 390px) and (max-width: 409px)
    font-size: 19px

  @media screen and (min-width: 410px) and (max-width: 767px)
    font-size: 20px

body
  background: var(--background-page-body)
  display: flex
  flex-direction: column
  font-size: 100%
  height: 100%
  min-height: 1vh
  +text-align_ltr(left)

  .root-v1 &
    +font-v1

  .root-v3 &
    +font-v1

  .root-v4 &
    +font-v4
    font-weight: 500

  & > .inner
    +min_height-100vh
    display: flex
    flex-direction: column
    height: 100%

    #header
      flex-shrink: 0

    #container_wrapper
      flex-grow: 1
      flex-shrink: 0

    #footer
      flex-shrink: 0

picture
  display: block // removes area outside of img - https://stackoverflow.com/questions/38361657/why-does-my-html5-picture-have-a-height-outside-of-its-img-and-why-does-it-n
  line-height: 0

  img
    +rtl_flip

[role=button]
  appearance: none

// Временные стили, для старой главной, которая по просьбе маркетинга
// будет работать на pages#original
.p-pages-original
  +gte_laptop
    --h2-font-size: 34px
    --padding-between-blocks: 60px

    article.v1
      padding-top: var(--padding-between-blocks)

      &.red_headline
        padding-top: 0

    article.v4
      &.landing_lead_form
        margin-bottom: -60px

h1
  +h1

h2
  +h2

h3
  +h3

h4
  +h4

p,
.subheadline,
ul,
ol
  +text_font
  // цвет должен наследоваться от корневого элемента
  // color: var(--text-base)

a
  +link

  &.b-link
    +link(var(--link-color))

b,
strong
  font-weight: 700

em
  font-style: italic

input
  border: none

  &:focus
    outline: none

input
  +placeholder
    text-overflow: ellipsis

.hidden
  display: none !important

h1,
h2,
h3
  span
    color: var(--primary-color)
    opacity: 0.9

.container
  +container

  &-desktop
    +container-desktop

  &-mobile
    +container-mobile

.dark-wrapper
  background: var(--gray-900)
  color: #fff

.subheadline
  +mx-auto
  text-align: center

  +lte_ipad
    max-width: rem(280px)
    margin-bottom: rem(36px)

    p:not(:last-child)
      margin-bottom: rem(15px)

  +gte_laptop
    max-width: 500px
    margin-bottom: 78px

  &.long-text
    +gte_laptop
      max-width: 800px

article
  &.v1
    margin-bottom: var(--v1-article-margin-bottom)

    +lte_ipad
      --v1-article-margin-bottom: #{rem(100px)}

    +gte_laptop
      --v1-article-margin-bottom: 180px

    h2
      +ltr_rtl
        text-align: center

      +lte_ipad
        margin-bottom: rem(24px)
        +mx-auto
        max-width: rem(264px)

      +gte_laptop
        margin-bottom: 36px

      &.is-missing-subheadline
        +lte_ipad
          margin-bottom: rem(28px)

        +gte_laptop
          margin-bottom: 56px

  &.v3
    --headline-font-weight: 800
    +lte_ipad
      --h1-font-size: #{rem(40px)}
      --h1-line-height: #{rem(48px)}
      --h2-font-size: #{rem(28px)}
      --h2-line-height: #{rem(32px)}
    +gte_laptop
      --h1-font-size: 72px
      --h1-line-height: 88px
      --h2-font-size: 48px
      --h2-line-height: 48px

    padding-top: var(--padding-between-blocks)

    h2
      +text-align_ltr(left)
      margin: 0

    &.is-rounded-background
      +container-desktop

      .inner
        +container-mobile
        background: var(--article-background)

        +lte_ipad
          border-radius: rem(20px)
          padding-bottom: rem(48px)
          padding-top: rem(48px)

        +gte_laptop
          border-radius: 28px
          padding: 56px 48px

    .above-headline
      +l1
      color: var(--primary-color)
      margin-bottom: var(--padding-md)

    .below-headline
      margin-top: var(--padding-lg)

      +lte_ipad
        +p3

      +gte_laptop
        +p1

  &.v4
    padding-bottom: var(--padding-between-blocks)
    padding-top: var(--padding-between-blocks)

    +lte_ipad
      --article-headline-gap: #{rem4(32px)}

    +gte_laptop
      --article-headline-gap: 40px

    &,
    & > .container
      align-items: center
      display: flex
      flex-direction: column
      gap: var(--article-headline-gap)

      & > section,
      & > form,
      & > div
        width: 100%

    header
      align-items: center
      display: flex
      flex-direction: column
      width: 100%

      +lte_ipad
        gap: rem4(16px)

      +gte_laptop
        gap: 24px

      &.left-aligned
        h1,
        h2,
        .note
          align-self: flex-start

      &:not(.left-aligned)
        h1,
        h2,
        .note
          text-align: center

      h1,
      h2,
      .note
        &:not(.full-width)
          +gte_laptop
            max-width: 764px

      h1,
      h2
        margin: 0

      .note
        +lte_ipad
          +p3

        +gte_laptop
          +p2

    // fixes swiper stretches itself into infinity
    .swiper-v8
      align-self: normal

.background-cutter
  overflow: hidden

// ----------------------------------
// Yandex Kassa
// ----------------------------------

.yandex-checkout-cardpayment__content
  margin-top: rem(120px) !important

.yandex-checkout-bank-card__cvc
  margin-top: rem(40px) !important

.yandex-checkout-bank-card__cvc-label
  line-height: rem(12px)

// ----------------------------------

@keyframes loader
  from
    -webkit-transform: rotate(0deg)
    transform: rotate(0deg)

  to
    -webkit-transform: rotate(360deg)
    transform: rotate(360deg)

@import 'node_modules/swiper/swiper-bundle.min'
.swiper-wrapper
  box-sizing: border-box

// NOTE: pages styles must be at the bottom so they have higher priority than default styles in application.sass
@import ./lib/columns
@import ./lib/animated

@import ./custom_content

@import ./blocks/b-svg-icon
@import ./blocks/*
@import ./pages/*/*
@import ./pages/*.sass
@import ./articles/*.sass
@import ./articles/*/*

@import ./admin/blocks/b-gallery
@import ./admin/blocks/b-storage_map
@import ./admin/blocks/b-collection_image_item
