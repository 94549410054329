article.v4.faq
  --h2-font-size: var(--h1-font-size)
  --h2-line-height: var(--h1-line-height)

  +container
  padding-bottom: 0

  +gte_laptop
    align-items: flex-start
    flex-direction: row
    gap: 24px

    .first
      flex-shrink: 0
      width: 416px

  .second
    display: flex
    flex-direction: column
    flex-grow: 1

    +lte_ipad
      gap: rem4(12px)

    +gte_laptop
      gap: 16px

  header
    +lte_ipad
      margin-bottom: rem4(32px)

    +gte_laptop
      margin-bottom: 32px

    h2,
    .note
      text-align: left

  .button
    +button_v4('primary', 'xl')

  & + article.v4.faq
    padding-top: 0
