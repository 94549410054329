.p-users-agreements.p-agreements
  .b-modal-storage-usage-rules
    background-color: #fff
    height: 100%
    +left_ltr(0)
    position: fixed
    top: 0
    width: 100%
    z-index: 10

    +lte_ipad
      padding: rem(30px) rem(16px)

    +gte_laptop
      padding: 30px 46px

    .body
      align-items: center
      display: flex
      flex-direction: column
      height: 100%
      justify-content: center
      position: relative

      .icon
        background-image: url('../images/pages/users/agreements/info-red.svg')
        background-repeat: no-repeat
        background-size: contain
        height: rem(56px)
        margin-bottom: rem(16px)
        width: rem(56px)

      a.modal-close
        background-image: url('../images/pages/users/agreements/x.svg')
        background-repeat: no-repeat
        background-size: contain
        display: inline-block
        height: rem(24px)
        position: absolute
        +right_ltr(0)
        top: 0
        width: rem(24px)

        &:hover
          opacity: 0.8

        &:active
          opacity: 0.9

      .title
        font-size: rem(24px)
        font-weight: bold
        line-height: rem(28px)
        margin-bottom: rem(24px)
        text-align: center

      .inner
        max-width: 540px

        ul
          margin-bottom: rem(20px)

          li
            color: var(--text-secondary)
            margin-bottom: rem(16px)
            +padding-left_ltr(rem(20px))
            position: relative
            font-size: rem(14px)

            &:before
              background-color: var(--primary-color)
              border-radius: rem(4px)
              content: ''
              height: rem(8px)
              +left_ltr(0)
              position: absolute
              top: rem(6px)
              width: rem(8px)

        .warning
          background-color: #ffeade
          border-radius: rem(12px)
          color: var(--primary-color)
          padding: rem(16px) rem(24px)

      p
        color: var(--text-secondary)
        font-size: rem(14px)
        margin: 0 0 rem(16px)
        +text-align_ltr(left)

  &.p-agreements-index
    h2
      font-size: rem(20px)
      line-height: rem(24px)
      margin: rem(40px) 0 rem(24px)

    a.box
      display: flex

      +lte_ipad
        flex-direction: column

      +gte_laptop
        align-items: center
        flex-direction: row
        justify-content: space-between

      &:hover
        color: var(--text-headline) !important

      .details
        display: flex
        flex-direction: column

        .name
          color: $secondary
          font-size: rem(32px)
          font-weight: 700
          line-height: rem(36px)
          margin-bottom: rem(8px)
          display: block

          &.unused
            color: var(--text-base)

        .address
          color: var(--text-base)
          line-height: 1.3

          +lte_ipad
            font-size: rem(14px)
            margin-bottom: rem(16px)

          +gte_laptop
            font-size: 16px

        .state
          font-size: rem(20px)
          line-height: rem(28px)

  &.p-agreements-show
    .box
      margin-bottom: rem(40px)
      position: relative

      .info
        margin-bottom: rem(12px)

        +lte_ipad
          text-align: center

        +gte_laptop
          display: flex
          align-items: center

        .number
          color: var(--secondary-color)
          font-size: rem(44px)
          font-weight: 700
          line-height: rem(48px)

          +gte_laptop
            +margin-right_ltr(56px)

        .note
          color: var(--text-secondary)
          font-size: rem(14px)
          line-height: 1.3

          &.warning
            color: var(--primary-color)

      .address
        color: var(--text-base)
        font-size: rem(16px)
        line-height: 1.3

        +lte_ipad
          text-align: center

      .details-grid
        +lte_ipad
          margin-top: rem(24px)

        +gte_laptop
          display: grid
          gap: rem(26px) rem(20px)
          grid-template-areas: 'state storage-access-time temperature' 'pin payment humidity'
          grid-template-columns: 1fr 1fr 1fr
          grid-template-rows: auto auto
          margin-top: rem(32px)

          .state
            grid-area: state
          .pin
            grid-area: pin

          .storage-access-time
            grid-area: storage-access-time
          .payment
            grid-area: payment
          .temperature
            grid-area: temperature
          .humidity
            grid-area: humidity

        .state,
        .pin,
        .storage-access-time,
        .payment,
        .temperature,
        .humidity
          display: flex
          line-height: rem(24px)
          +padding-left_ltr(rem(36px))
          position: relative

          +lte_ipad
            font-size: rem(14px)

            &:not(:last-child)
              margin-bottom: rem(16px)

          &:before
            background-repeat: no-repeat
            background-size: contain
            content: ''
            height: rem(24px)
            +left_ltr(0)
            position: absolute
            top: 0
            width: rem(24px)

          .label
            color: var(--text-secondary)
            +margin-right_ltr(rem(4px))

          .value
            font-weight: 700

        .state
          &:before
            background-image: url('../images/pages/users/agreements/icon-lock.svg')

        .pin
          .skud-password
            b
              background-repeat: no-repeat
              background-position: center right
              +padding-right_ltr(rem(26px))
              cursor: pointer

              &.mask
                color: var(--text-base)
                background-image: url('../images/pages/dashboards/icon-eye-open.svg')

              &.value
                background-image: url('../images/pages/dashboards/icon-eye-closed.svg')

          &:before
            background-image: url('../images/pages/users/agreements/icon-pin.svg')

        .storage-access-time
          &:before
            background-image: url('../images/pages/users/agreements/icon-clock.svg')

        .payment
          &:before
            background-image: url('../images/pages/users/agreements/icon-card.svg')

        .temperature
          &:before
            background-image: url('../images/pages/users/agreements/icon-temperature.svg')

        .humidity
          &:before
            background-image: url('../images/pages/users/agreements/icon-humidity.svg')

      .close-agreement
        +lte_ipad
          font-size: rem(14px)
          margin-top: rem(24px)
          text-align: center

        +gte_laptop
          position: absolute
          +right_ltr(24px)
          top: 24px

        a
          +link_underline

    .b-switcher
      margin-bottom: rem(24px)

    .slides
      +lte_ipad
        padding-bottom: rem(1px) // a hack to make margin-bottom of last .bill to work on mobile

    .credit-card-note
      background-color: #d7e0ef
      border-radius: rem(12px)
      color: var(--text-base)
      font-size: rem(14px)
      line-height: 1.3
      margin-bottom: rem(24px)
      padding: rem(16px) rem(24px)

      a
        +link_underline

        +lte_ipad
          display: block

        +gte_laptop
          +margin-left_ltr(8px)

    .credit-card-next-payment
      display: flex

      +lte_ipad
        flex-direction: column

      +gte_laptop
        align-items: center
        justify-content: space-between

      .info
        .title
          +lte_ipad
            font-size: rem(18px)
            line-height: 1.3

          +gte_laptop
            font-size: 20px
            line-height: 24px

        .subtitle
          color: var(--text-base)
          line-height: 1.3
          margin-top: rem(8px)

          +lte_ipad
            font-size: rem(12px)

          +gte_laptop
            font-size: 14px

      .amount
        font-size: rem(32px)
        font-weight: 700
        line-height: rem(36px)

        +lte_ipad
          margin-top: rem(8px)

    .payment-methods
      margin-bottom: rem(24px)

      +gte_laptop
        display: grid
        grid-template-columns: 1fr 1fr 1fr
        grid-template-rows: 1fr
        gap: 0px 48px
        grid-template-areas: '. . .'

      .item
        display: flex
        align-items: center

        +lte_ipad
          &:not(:last-child)
            margin-bottom: rem(16px)

        a
          +link_underline
          color: var(--primary-color) !important

        .icon
          background-color: #fff
          background-position: center
          background-repeat: no-repeat
          flex-shrink: 0
          +margin-right_ltr(rem(16px))

          +lte_ipad
            border-radius: rem(22px)
            height: rem(44px)
            width: rem(44px)

          +gte_laptop
            width: 56px
            height: 56px
            border-radius: 28px

          &.icon-card
            background-image: url('../images/pages/users/agreements/icon-grey-card.svg')
          &.icon-bank
            background-image: url('../images/pages/users/agreements/icon-grey-bank.svg')
          &.icon-puzzle
            background-image: url('../images/pages/users/agreements/icon-grey-puzzle.svg')

    .bill
      background: #fff
      border-radius: rem(12px)
      box-shadow: 0px 22px 44px -22px rgba(0, 0, 0, 0.15)
      margin-bottom: rem(24px)
      overflow: hidden
      position: relative

      .mobile-header
        color: #fff
        font-size: rem(12px)
        font-weight: 700
        line-height: 1.3
        margin: rem(-1px) rem(-17px) rem(25px) // 16px compensates .block-container padding + 1px because of the border
        order: -2
        padding: rem(6px) 0
        text-align: center
        width: calc(100% + #{rem(34px)}) // 2 * (16px (padding) + 1px (border))

        &.white
          background-color: var(--primary-color)

        &.blue
          background-color: var(--secondary-color)

        &.red
          background-color: var(--primary-color)

        &.black
          background-color: var(--text-strong)

        &.grey
          background-color: #939393

        +gte_laptop
          display: none

      .bill-info.block-container
        border-radius: 0
        box-shadow: none
        cursor: pointer
        margin-bottom: 0 !important
        position: relative

        +lte_ipad
          display: flex
          flex-direction: column
          padding-top: 0

        +gte_laptop
          align-items: center
          display: grid
          gap: 0px 48px
          grid-template-areas: '. . . .'
          grid-template-columns: 1.5fr 1fr 1fr 1fr
          grid-template-rows: 1fr

        // <!-- arrow start
        &:after
          background-image: url('../images/pages/users/agreements/bills/expand_arrow.svg')
          background-repeat: no-repeat
          background-size: contain
          content: ''
          position: absolute
          transition: transform var(--transition-duration)

          +lte_ipad
            height: rem(17px)
            right: rem(16px)
            top: rem(63px)
            width: rem(32px)

          +gte_laptop
            height: 12px
            +right_ltr(24px)
            top: 50%
            transform: translateY(-50%)
            width: 24px

        +gte_laptop
          &:hover:after
            background-image: url('../images/pages/users/agreements/bills/expand_arrow-hover.svg')

        &:active:after
          background-image: url('../images/pages/users/agreements/bills/expand_arrow-active.svg')

        &.is-expanded:after
          +lte_ipad
            transform: rotate(180deg)

          +gte_laptop
            transform: translateY(-50%) rotate(180deg)
        // arrow end -->

        &.white,
        &.blue
          .title
            color: var(--text-headline)

          .subtitle
            color: var(--text-base)

          .payment-state
            color: var(--text-secondary)

          .action
            a.pay
              +button_red

            a.download
              +link_underline

        &.red,
        &.black

          &:after
            background-image: url('../images/pages/users/agreements/bills/expand_arrow-special.svg') !important

          .title,
          .subtitle,
          .payment-state,
          .amount
            color: #fff

          .action
            a.pay
              +button_white

            a.download
              +link_white

        &.red
          border-color: var(--primary-color)
          background-color: var(--primary-color)

        &.black
          border-color: var(--text-strong)
          background-color: var(--text-strong)

        &.grey
          color: var(--text-secondary)

        .general-info
          +lte_ipad
            margin-bottom: rem(26px)

          +gte_laptop
            +padding-left_ltr(72px)
            position: relative

            &:before
              background-position: center
              background-repeat: no-repeat
              content: ''
              height: rem(56px)
              +left_ltr(0)
              position: absolute
              top: rem(-3px)
              width: rem(56px)

            &.pending:before
              background-image: url('../images/pages/users/agreements/bills/icon-bill-pending.svg')

            &.pending_duedate:before
              background-image: url('../images/pages/users/agreements/bills/icon-bill-pending-duedate.svg')

            &.paid:before
              background-image: url('../images/pages/users/agreements/bills/icon-bill-paid.svg')

            &.paid_penalty:before
              background-image: url('../images/pages/users/agreements/bills/icon-bill-penalty-paid.svg')

            &.penalty:before
              background-image: url('../images/pages/users/agreements/bills/icon-bill-penalty-pending.svg')

            &.rejected:before
              background-image: url('../images/pages/users/agreements/bills/icon-bill-rejected.svg')

          .title
            font-weight: 700
            margin-bottom: rem(8px)

            +lte_ipad
              font-size: rem(16px)
              line-height: 1.3

            +gte_laptop
              font-size: 20px
              line-height: 24px

          .subtitle
            line-height: 130%

            +lte_ipad
              font-size: rem(12px)

            +gte_laptop
              font-size: 14px

        .payment-state
          font-size: rem(14px)
          line-height: 130%

          +lte_ipad
            display: none

        .amount
          font-size: rem(32px)
          font-weight: 700
          line-height: rem(36px)

          +lte_ipad
            order: -1
            margin-bottom: rem(27px)

        .action
          display: flex
          flex-direction: column
          align-items: center

          +gte_laptop
            +padding-right_ltr(32px)

          a.pay
            +lte_ipad
              margin-bottom: rem(12px)
              width: 100%

            +gte_laptop
              margin-bottom: 8px

          a.download
            font-size: rem(14px)

          .paid
            color: var(--secondary-color)
            font-size: rem(20px)
            font-weight: 700
            line-height: rem(24px)
            margin-bottom: rem(16px)

            +lte_ipad
              display: none

      .bill-details
        background-color: rgba(#eff3fa, 0.4)
        padding: rem(24px)

        &.red,
        &.black
          background-color: #f3703c
          color: #fff

          .item
            color: #fff

        &.black
          background-color: #59595b

        .divider
          height: 2px
          background-color: #e6ecf6
          margin: rem(20px) 0

        .items
          display: flex

          +lte_ipad
            flex-direction: column

        .item
          color: var(--text-base)
          font-size: rem(14px)
          line-height: 130%
          +margin-right_ltr(rem(56px))

      .products
        +lte_ipad
          display: none

        .products-title
          font-size: rem(20px)
          line-height: rem(24px)
          font-weight: 700
          margin-bottom: rem(20px)

        table
          font-size: rem(14px)
          width: 100%

          td
            padding: rem(16px)

            &.right
              +text-align_ltr(right)
              width: 200px

            &.amount
              font-weight: 700

        table,
        tr,
        td
          border: 1px solid #eaeaea

    .slide-agreement-info
      padding-bottom: rem(40px)

      .item
        +lte_ipad
          margin-bottom: rem(14px)

        .label
          color: var(--text-secondary)
          font-size: rem(12px)
          line-height: rem(14px)
          margin-bottom: rem(8px)

        .value
          color: var(--text-base)
          font-size: rem(16px)
          line-height: 1.3

          a
            +link_underline
            +margin-left_ltr(rem(6px))

      .box-details
        +gte_laptop
          display: grid
          gap: 16px 16px
          grid-template-areas: '. . .' '. . .' '. . .'
          grid-template-columns: 1fr 1fr 1fr
          grid-template-rows: 1fr 1fr 1fr

      .agreement-details
        +gte_laptop
          display: grid
          gap: 16px 16px
          grid-template-areas: '. . .' '. . .' '. . .'
          grid-template-columns: 1fr 1fr 1fr
          grid-template-rows: 1fr 1fr 1fr

      .agreement-close-info
        .steps
          display: flex
          margin-bottom: rem(32px)

          +lte_ipad
            flex-direction: column
            align-items: center

          +gte_laptop
            position: relative
            justify-content: space-between

          .line
            background-color: var(--secondary-color)
            height: rem(16px)
            +left_ltr(6%)
            position: absolute
            top: rem(42px)
            width: 88%

            +lte_ipad
              display: none

          .step
            color: var(--text-base)
            line-height: 1.3
            text-align: center

            +lte_ipad
              font-size: rem(16px)

            +gte_laptop
              font-size: 14px
              max-width: 16%
              z-index: 1

            .circle
              align-items: center
              background-color: var(--primary-color)
              color: #fff
              display: flex
              font-weight: 700
              justify-content: center
              margin: 0 auto rem(12px)

              +lte_ipad
                border-radius: rem(22px)
                font-size: rem(23px)
                height: rem(44px)
                width: rem(44px)

              +gte_laptop
                border-radius: 50px
                font-size: 56px
                height: 100px
                width: 100px

        .subtitle
          color: var(--text-base)
          font-size: rem(16px)
          font-weight: 700
          line-height: 1.3
          margin-bottom: rem(16px)

        ol
          list-style: decimal
          margin-bottom: rem(44px)
          +margin-left_ltr(rem(16px))

          li
            color: var(--text-base)
            font-size: rem(16px)
            line-height: 1.3
            margin-bottom: rem(20px)
            +padding-left_ltr(rem(8px))

            a
              +link_underline

        a.close
          +button_red

          +gte_laptop
            width: 240px

  &.p-agreements-closing
    a.close
      +button_red

      +gte_laptop
        +margin-right_ltr(24px)

    a.back
      +button_frame_red

    p
      font-size: rem(14px)
      line-height: 1.3

      &.warning
        background-color: #ffeade
        border-radius: rem(12px)
        color: var(--primary-color)
        padding: rem(16px) rem(24px)

      a
        +link_underline

    .recalc-penalty-amount
      background-color: #eff3fa
      border-radius: rem(12px)
      color: var(--text-base)
      margin: rem(24px) 0 rem(16px)
      padding: rem(16px) 0
      text-align: center
      width: 100%

    .container-flex
      +gte_laptop
        display: grid
        grid-template-columns: 1fr 344px
        grid-template-rows: 1fr
        gap: 0px 24px
        grid-template-areas: '. .'

    label.confirm
      align-items: flex-start
      color: var(--text-base)
      cursor: pointer
      display: block
      display: flex
      font-size: rem(14px)
      line-height: 1.3
      margin: rem(24px) 0

      input.confirm
        cursor: pointer
        flex-shrink: 1
        height: rem(20px)
        +margin-right_ltr(rem(8px))
        min-height: rem(20px)
        min-width: rem(20px)
        width: rem(20px)

    select
      margin-bottom: 44px

    input[type='submit']
      +lte_ipad
        width: 100%
        margin-bottom: rem(12px)

    .back
      +gte_laptop
        +margin-left_ltr(24px)

    .block-container.deposit
      display: flex
      flex-direction: column
      align-items: center

      .icon
        background-image: url('../images/pages/users/agreements/info-red.svg')
        background-repeat: no-repeat
        background-size: contain
        height: rem(56px)
        margin-bottom: rem(24px)
        width: rem(56px)

      .title
        font-size: rem(20px)
        font-weight: 700
        line-height: rem(24px)
        margin-bottom: rem(24px)

      .amount
        background-color: #eff3fa
        border-radius: rem(12px)
        color: var(--text-base)
        margin-bottom: rem(16px)
        padding: rem(16px) 0
        text-align: center
        width: 100%
