.b-breadcrumbs-v4
  +container_desktop
  +lte_ipad
    margin-left: var(--container-padding-x)
    margin-top: rem4(21px)

  +gte_laptop
    align-items: center
    display: flex
    gap: 16px
    margin-top: -15px

  +lte_ipad
    &.is-absolute
      .back
        position: absolute
        margin-left: rem4(9px)
        margin-top: rem4(24px)
        margin-bottom: 0
        z-index: 1

  .back
    +button_v4('secondary', 'sm', $is_empty: true)

    +lte_ipad
      border: 1px solid #fff
      height: rem4(32px)
      width: rem4(32px)

    +gte_laptop
      height: 30px
      width: 30px

    &:before
      +font-icon('\E82C')

      +lte_ipad
        font-size: rem4(16px)

      +gte_laptop
        font-size: 15px

  .list
    +p5

    +lte_ipad
      display: none

    +gte_laptop
      display: flex

    & > span
      list-style: none

      a
        color: var(--gray-500)

.b-breadcrumbs-v4 + article
  +lte_ipad
    padding-top: rem4(16px)

  +gte_laptop
    margin-top: 16px
    padding-top: 0

    header
      +gte_laptop
        gap: 8px

      .note
        +gte_laptop
          +p3
