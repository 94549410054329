.b-content_placeholder
  display: none

  body[data-env='development'] &
    display: block

  a
    +p5
    align-items: center
    display: flex
    gap: 8px

  .separator
    align-self: center
    background: var(--gray-900)
    display: flex
    height: 2px
    width: 2px

