.p-pages-root.p-v4 article.slide_4

  +container_desktop

  +lte_ipad
    padding-bottom: 0

  h2,
  .note
    +container_mobile
