// костыль, чтобы не-кликабельная область маркера не загораживали другие маркеры
.ymaps3x0--marker
  pointer-events: none

  .b-map-marker-v4
    pointer-events: all

// no rem here
.b-map-marker-v4
  position: relative

  &.is-yandex
    transform: translateX(-50%) translateY(-100%)

  svg
    color: #fff
    cursor: pointer
    filter: drop-shadow(0px 0.933px 1.867px rgba(0, 0, 0, 0.18))
    stroke-width: 0.467px
    stroke: rgba(34, 34, 34, 0.10)

  .text
    align-items: center
    cursor: pointer
    display: flex
    font-size: 14px
    font-weight: 600
    gap: 2px
    height: 32px
    justify-content: center
    left: 0
    line-height: 1.3
    position: absolute
    text-align: center
    top: 0
    width: 100%
    z-index: 1

    span
      color: var(--gray-400)

    &.is-soon:before
      +font-icon('\E828')
      font-size: 16px

    &.is-new:after
      +font-icon('\E829')
      color: var(--primary-color)
      font-size: 16px

  svg,
  .text,
  .text:before
    transition: color var(--transition-duration)

  +gte_laptop
    &.is-hover,
    &:hover
      svg
        color: var(--gray-100)

  =active_svg
    svg
      color: var(--gray-900)

    .text
      color: #fff

      &.is-soon:before
        color: var(--primary-color)

  +lte_ipad
    &.is-active,
    &.is-pressed
      +active_svg

  +gte_laptop
    &.is-active,
    &.is-pressed,
    &:active
      +active_svg

.map-balloon-v4
  +lte_ipad
    bottom: rem4(35px)
    left: 50%
    position: absolute
    transform: translateX(-50%)
    z-index: $z-index-sticky_area - 1

  +gte_laptop
    margin-left: 50%
    margin-top: -16px
    position: absolute
    transform: translateX(-50%) translateY(-100%)

    &.is-right
      margin-left: 122px
      margin-top: 20px
      position: absolute
      transform: translateX(0%) translateY(-50%)
