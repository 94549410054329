=headline_v1
  +mx-auto
  +lte_ipad
    text-align: center

=h1
  color: var(--text-strong)
  font-size: var(--h1-font-size)
  font-weight: var(--headline-font-weight)
  line-height: var(--h1-line-height)
  letter-spacing: var(--h1-letter-spacing)

  // .v1 &
  +headline_v1
  +gte_laptop
    text-align: center

=h2
  color: var(--text-strong)
  font-size: var(--h2-font-size)
  font-weight: var(--headline-font-weight)
  line-height: var(--h2-line-height)

  // .v1 &
  +headline_v1
  margin-bottom: var(--headline-margin)

=h3
  color: var(--text-strong)
  font-size: var(--h3-font-size)
  font-weight: var(--headline-font-weight)
  line-height: var(--h3-line-height)

  // .v1 &
  +headline_v1
  margin-bottom: var(--headline-margin)

=h4
  color: var(--text-strong)
  font-size: var(--h4-font-size)
  font-weight: var(--headline-font-weight)
  line-height: var(--h4-line-height)

=h4
  font-weight: 700
  font-size: var(--h4-font-size)
  line-height: var(--h4-line-height)
  letter-spacing: var(--h4-letter-spacing)

=p1($font-weight: var(--p2-font-weight))
  font-size: var(--p1-font-size)
  font-weight: $font-weight
  letter-spacing: var(--p1-letter-spacing)
  line-height: var(--p1-line-height)

=p2($font-weight: var(--p2-font-weight))
  font-size: var(--p2-font-size)
  font-weight: $font-weight
  letter-spacing: var(--p2-letter-spacing)
  line-height: var(--p2-line-height)

=p3($font-weight: var(--p3-font-weight))
  font-size: var(--p3-font-size)
  font-weight: $font-weight
  letter-spacing: var(--p3-letter-spacing)
  line-height: var(--p3-line-height)

=p4($font-weight: var(--p4-font-weight))
  font-size: var(--p4-font-size)
  font-weight: $font-weight
  letter-spacing: var(--p4-letter-spacing)
  line-height: var(--p4-line-height)

=p5($font-weight: var(--p5-font-weight))
  font-size: var(--p5-font-size)
  font-weight: $font-weight
  letter-spacing: var(--p5-letter-spacing)
  line-height: var(--p5-line-height)

=l1
  font-weight: 700
  line-height: 1
  text-transform: uppercase

  +lte_ipad
    font-size: rem(12px)
    letter-spacing: rem(0.6px)

  +gte_laptop
    font-size: 14px
    letter-spacing: 0.7px

=w1
  +font-cursive
  font-weight: 700
  line-height: 1

  +lte_ipad
    font-size: rem(28px)

  +gte_laptop
    font-size: 32px

=b1
  font-size: var(--b1-font-size)
  font-weight: var(--b1-font-weight)
  letter-spacing: var(--b1-letter-spacing)
  line-height: var(--b1-line-height)

=b2
  font-size: var(--b2-font-size)
  font-weight: var(--b2-font-weight)
  letter-spacing: var(--b2-letter-spacing)
  line-height: var(--b2-line-height)
