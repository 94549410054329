.p-pages-root.p-v4
  --h2-font-size: var(--h1-font-size)
  --h2-line-height: var(--h1-line-height)

  article
    +lte_ipad
      --article-headline-gap: var(--spacing-6xl)

    +gte_laptop
      --article-headline-gap: 64px

    &.dark-wrapper
      h2,
      .note
        color: var(--gray-25)

    // отступ в футере
    &:last-child
      margin-bottom: 0 !important

  article.v4.storages_map
    gap: rem4(24px)

    .storages-filters
      +gte_laptop
        justify-content: center
