article.v4.reviews
  +container 
  position: relative
  padding-top: 0

  h2.ru
    max-width: 825px

  .footer-gradient
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #fff 100%)
    bottom: 0
    height: 344px
    left: 0
    pointer-events: none
    position: absolute
    width: 100%
    z-index: 1

  .reviews
    width: 100%

    +lte_ipad
      display: flex
      flex-direction: column
      gap: rem4(12px)

    +gte_laptop
      display: grid
      gap: 24px
      grid-template-columns: 1fr 1fr 1fr

    .column
      flex-direction: column

      +lte_ipad
        gap: rem4(12px)
        display: none

        &:first-child
          display: flex

      +gte_laptop
        display: flex
        gap: 24px

  footer
    .load-more
      +button_v4('primary', 'xl')
      position: relative
      z-index: 1

      +gte_laptop
        display: none

  &.is-loaded_more
    .reviews .column
      display: flex

    .load-more
      display: none

  .review
    background: #fff
    display: flex
    flex-direction: column
    gap: var(--spacing-md)
    position: relative

    +lte_ipad
      border-radius: rem4(24px)
      box-shadow: rem4(-4px) rem4(8px) rem4(20px) 0px rgba(0, 0, 0, 0.10)
      padding: rem4(24px)

    +gte_laptop
      border-radius: 24px
      box-shadow: -4px 8px 20px 0px rgba(0, 0, 0, 0.10)
      padding: 24px

    .link
      align-items: center
      display: flex
      font-weight: 600
      line-height: 1.5
      position: absolute

      +lte_ipad
        background: var(--gray-100)
        border-radius: rem4(24px)
        font-size: rem4(12px)
        gap: rem4(4px)
        height: rem4(24px)
        padding: 0px rem4(12px) 0px var(--spacing-md)
        right: rem4(24px)
        top: rem4(24px)

      +gte_laptop
        background: var(--gray-100)
        border-radius: 24px
        font-size: 12px
        gap: 4px
        height: 24px
        padding: 0px 12px 0px var(--spacing-md)
        right: 24px
        top: 24px

      &:before
        background-image: url('../images/articles/v4/reviews/shield.svg')
        background-repeat: no-repeat
        background-size: contain
        content: ''

        +lte_ipad
          height: rem4(16px)
          width: rem4(16px)

        +gte_laptop
          height: 16px
          width: 16px

    .author
      +p3(700)

      +lte_ipad
        margin-bottom: rem4(4px)

      +gte_laptop
        margin-bottom: 4px

    .storage
      color: var(--gray-500)
      font-weight: 500
      line-height: 1.5

      +lte_ipad
        font-size: rem4(14px)

      +gte_laptop
        font-size: 14px

    .stars:before
      +font-icon('\E829\E829\E829\E829\E829')
      color: var(--primary-color)
      font-size: 20px

    .text
      +p3

      +lte_ipad
        letter-spacing: rem4(0.16px)

      +gte_laptop
        letter-spacing: 0.16px

