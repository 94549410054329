.p-storages-index .storages-v4-index
  h1
    align-self: flex-start
    margin-left: 0
    margin-right: 0
    text-align: left !important

  .additional-seo
    margin-bottom: 0

    +lte_ipad
      padding-top: rem4(40px)

    +gte_laptop
      padding-top: calc(var(--padding-between-blocks) * 0.5)

    h2.left
      +text-align_ltr(left)

    a
      +link(var(--primary-color))

      &.read-more.active
        display: none

    .spoiler
      &.active
        +gte_laptop
          margin-bottom: 48px

      &:not(.active)
        display: none

    h2
      +lte_ipad
        font-size: rem4(24px)
        margin-bottom: rem4(10px)

      +gte_laptop
        font-size: 24px
        margin-bottom: 10px

    h3
      +lte_ipad
        font-size: rem4(18px)
        margin-bottom: rem4(8px)

      +gte_laptop
        font-size: 18px
        margin-bottom: 8px

    p,
    ul,
    ol
      color: var(--text-base)

      +lte_ipad
        margin-bottom: rem4(18px)

      +gte_laptop
        margin-bottom: 18px

    p
      &.italic
        font-style: italic

    ul
      list-style-type: disc

      +lte_ipad
        +margin-left_ltr(rem4(30px))

      +gte_laptop
        +margin-left_ltr(30px)

      li
        +lte_ipad
          margin-bottom: rem4(10px)

        +gte_laptop
          margin-bottom: 10px

    ol
      list-style-type: decimal

      +lte_ipad
        +margin-left_ltr(rem4(30px))

      +gte_laptop
        +margin-left_ltr(30px)


      li
        +lte_ipad
          margin-bottom: rem4(10px)

        +gte_laptop
          margin-bottom: 10px

    table
      +lte_ipad
        margin-bttom: rem4(20px)

      +gte_laptop
        margin-bttom: 20px

      th,
      td
        border-color: var(--text-base)
        border-style: solid
        border-width: 1px
        border-spacing: 0
        border-collapse: separate
        padding: rem(10px)
        line-height: 1.3
      th
        font-weight: 600
        text-align: center
      td
        color: var(--text-base)

  .districts
    +lte_ipad
      margin-top: rem4(16px)

    +gte_laptop
      margin-top: 16px

    h3
      text-align: center

  .world-note
    +lte_ipad
      margin-top: rem4(16px)

    +gte_laptop
      margin-top: 24px
