.p-pages-root.p-v4 article.slide_1
  +container
  align-items: flex-start
  display: flex

  +lte_ipad
    --h1-font-size: #{rem4(48px)}
    --h1-font-weight: 700
    --h1-line-height: 1.2
    --h1-letter-spacing: #{rem4(-1.44px)}

  +gte_laptop
    --h1-font-size: 64px
    --h1-font-weight: 700
    --h1-letter-spacing: -1.92px
    --h1-line-height: 1.2

  +lte_ipad
    flex-direction: column
    padding-top: rem4(22px)

  +gte-laptop
    // остальной отступ в топ меню
    padding-top: 0px

    flex-direction: row
    align-items: center
    gap: 24px

  h1
    +text-align_ltr(left)

  +lte_ipad
    flex-direction: column

  .first
    h1
      +lte_ipad
        margin-bottom: rem4(16px)

      +gte_laptop
        margin-bottom: 32px

    .note
      text-align: left

      +lte_ipad
        font-size: rem4(18px)
        line-height: rem4(26px)
        margin-bottom: rem4(24px)

      +gte_laptop
        font-size: 24px
        line-height: 1.3
        margin-bottom: 32px

    a
      +button_v4('primary', 'xl')

  .second
    +gte_ipad
      flex-basis: 41.35%
      flex-shrink: 0

    picture
      line-height: 0

      img
        width: 100%
        max-width: 100%
