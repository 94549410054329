=fbl
  background: var(--fbl-loader-background)
  border-radius: var(--fbl-border-radius)

  &:before
    color: rgba(0, 0, 0, 0)
    content: 'z'

.b-fbl-loader
  display: flex
  flex-direction: column
  overflow: hidden
  pointer-events: none
  position: relative

  --fbl-loader-background: #f2f2f2

  +lte_ipad
    --fbl-border-radius: #{rem4(16px)}
    gap: rem4(24px)

  +gte_laptop
    --fbl-border-radius: 16px
    gap: 24px

  .item
    display: flex
    flex-direction: column
    flex-grow: 1
    position: relative

    +lte_ipad
      gap: rem4(12px)

    +gte_laptop
      gap: 12px

    &:before
      $color1: rgba(255, 255, 255, 0) 8%
      $color2: #fff 18%
      $color3: rgba(255, 255, 255, 0) 33%

      animation-duration: 1.25s
      animation-fill-mode: forwards
      animation-iteration-count: infinite
      animation-name: fbl-animated-background-frame
      animation-timing-function: linear
      background: linear-gradient(to right, #{$color1}, #{$color2}, #{$color3})
      background-size: 800px 204px
      position: relative

      content: ''
      height: 100%
      left: 0
      position: absolute
      top: 1px
      width: 100%

  .modal-title,
  .modal-note,
  .modal-submit-note div
    +fbl

  .modal-submit-note
    display: flex
    flex-direction: column

    +lte_ipad
      gap: rem4(8px)

    +gte_laptop
      gap: 8px

    div
      +lte_ipad
        height: rem4(10px)

      +gte_laptop
        height: 10px

  .b-input.textarea textarea
    +lte_ipad
      height: rem4(114px)

    +gte_laptop
      height: 114px

@keyframes fbl-animated-background-frame
  from
    background-position: -468px 0

  to
    background-position: 468px 0
