=map_control
  background: #fff
  border-radius: 100px
  border: 1px solid transparent
  transition: background var(--transition-duration), border-color var(--transition-duration), color var(--transition-duration)
  user-select: none

  +lte_ipad
    padding: rem4(6px) rem4(12px)

  +gte_laptop
    padding: 6px 12px

  &:not(.is-active)
    cursor: pointer

    +gte_laptop
      &:hover
        background: #fdeee9
        border-color: #ff4f00
        color: #ff4f00

    &:active
      background: #fce7df
      border-color: #ff4f00
      color: #ff4f00

  &.is-active
    background: #fdeee9
    border-color: #ff4f00
    color: #ff4f00

    // +gte_laptop
    //   &:hover
    //     background: #fce7df
    //
    // &:active
    //   background: #fbdfd4

.b-storage_map
  position: relative

  .level.active
    background-color: #467fcf
    color: #fff

  .fabric-inputs
    align-items: center
    display: flex

    input
      font-size: 13px

      &.sizes
        width: 50px

      &.coords
        width: 65px

  .box-list
    max-height: 480px

    .box
      column-gap: 24px
      display: flex
      white-space: nowrap
      flex-wrap: wrap

      &.active .tag
        background-color: #467fcf
        color: #fff

      .tag
        display: inline-block
        min-width: 50px
        text-align: center

      .icon
        font-size: 18px

    .fabric-inputs
      display: inline-flex

  .map-inner
    background: #f0f3fa
    overflow: hidden
    position: relative
    width: 100%

    +lte_ipad
      border-radius: rem4(16px)

    +gte_laptop
      border-radius: 16px

    .controls
      display: flex
      flex-direction: column
      position: absolute

      +lte_ipad
        display: none
        // +right_ltr(rem4(24px))
        // bottom: rem4(24px)
        // gap: rem4(8px)

      +gte_laptop
        +right_ltr(24px)
        bottom: 24px
        gap: 8px

      .zoom-in,
      .zoom-out
        +map_control
        align-items: center
        color: var(--gray-700)
        cursor: pointer
        display: flex
        justify-content: center
        user-select: none

        +lte_ipad
          font-size: rem4(18px)
          height: rem4(34px)
          width: rem4(34px)
          line-height: rem4(34px)

        +gte_laptop
          font-size: 18px
          height: 34px
          line-height: 34px
          width: 34px

      .zoom-in
        +font_icon('+')

      .zoom-out
        +font_icon('-')

  .levels
    display: flex
    position: absolute

    +lte_ipad
      gap: rem4(8px)
      justify-content: center
      left: rem4(12px)
      right: rem4(12px)
      top: rem4(24px)

      &:has(> :last-child:nth-child(5)),
      &:has(> :last-child:nth-child(6)),
      &:has(> :last-child:nth-child(7)),
      &:has(> :last-child:nth-child(8)),
      &:has(> :last-child:nth-child(9)),
      &:has(> :last-child:nth-child(10))
        flex-wrap: wrap
        justify-content: flex-start

    +gte_laptop
      +left_ltr(24px)
      flex-wrap: wrap
      gap: 8px
      top: 24px

    .level
      +map_control
      color: var(--gray-900)
      flex-shrink: 0
      line-height: 1.3

      +lte_ipad
        font-size: rem4(16px)

      +gte_laptop
        font-size: 16px

  .legend
    background: #fff
    border-radius: 100px
    display: flex
    line-height: 1.3
    position: absolute

    +lte_ipad
      bottom: rem4(20px)
      font-size: rem4(16px)
      gap: rem4(16px)
      left: 50%
      padding: rem4(6px) rem4(12px)
      transform: translateX(-50%)

    +gte_laptop
      +left_ltr(24px)
      bottom: 24px
      font-size: 16px
      gap: 16px
      padding: 6px 12px

    .item
      display: flex

      +lte_ipad
        gap: rem4(8px)

      +gte_laptop
        gap: 8px

      .dot
        align-items: center
        display: flex
        justify-content: center

        &:before
          border-radius: 100%
          border: 1px solid #d0d5dd
          content: ''

          +lte_ipad
            height: rem4(12px)
            width: rem4(12px)

          +gte_laptop
            height: 12px
            width: 12px

        &.active:before
          background: #f0a884 // box.js COLORS.active

        &.available:before
          background: #bef4c3 // box.js COLORS.available

        &.not-available:before
          background: #e6e6e6 // box.js COLORS.disabled/other
