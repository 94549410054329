.p-boxes .boxes-v4-show
  article.slide_1
    header
      position: relative

      h1
        align-items: center
        display: flex

        +lte_ipad
          gap: rem4(16px)

        +gte_laptop
          gap: 24px

      .size
        border-radius: 100px
        background: var(--gray-100)

        +lte_ipad
          +p3
          min-width: rem4(48px)
          padding: rem4(4px) rem4(12px)

        +gte_laptop
          +p2(500)
          min-width: 48px
          padding: 6px 12px

      .rent
        +lte_ipad
          display: none

        +gte_laptop
          +button_v4('primary', 'xl')
          position: absolute
          right: 0
          bottom: 43px

    .photo.size
      align-items: center
      background: var(--gray-100)
      display: flex
      justify-content: center
      padding: 20px

      +laptop
        // 0.75 -> 960 / 1280
        border-radius: scale-laptop(0.75 * 24px, 24px)
        height: scale-laptop(0.75 * 312px, 312px)
        width: scale-laptop(0.75 * 312px, 312px)

      +gte_desktop
        border-radius: 24px
        height: 312px
        width: 312px

      img
        object-fit: contain

        +laptop
          height: scale-laptop(0.75 * 248px, 248px)
          width: scale-laptop(0.75 * 248px, 248px)

        +gte_desktop
          height: 248px
          width: 248px

    ul.properties
      display: flex
      flex-direction: column
      width: 100%

      +lte_ipad
        gap: rem4(24px)

      +gte_laptop
        gap: 24px

      li
        +p3(500)
        display: flex
        justify-content: space-between

        span:first-child
          align-items: center
          display: flex

          +lte_ipad
            gap: rem4(14px)

          +gte_laptop
            gap: 16px

        img
          +lte_ipad
            height: rem4(24px)
            width: rem4(24px)

          +gte_laptop
            height: 24px
            width: 24px

    .separator
      +lte_ipad
        margin-bottom: rem4(64px)

      +gte_laptop
        background: var(--gray-300)
        height: 1px
        margin-top: 32px
        width: 100%

        &:not(:last-child)
          margin-bottom: 32px

    .layout-columns
      +gte_laptop
        display: flex
        justify-content: space-between
        gap: 48px

      .content-column
        +gte_laptop
          flex-basis: 704px

        .guest-visit
          +lte_ipad
            margin-top: rem4(32px)

          +gte_laptop
            display: none

      .order-column
        +lte_ipad
          display: none

        +gte_laptop
          flex-basis: 432px

  .guide,
  .features
    h4
      +lte_ipad
        margin-bottom: rem4(20px)

      +gte_laptop
        margin-bottom: 20px

  .guest-visit
    +p4
    align-items: center
    background: #fff
    border: 1px solid var(--gray-100)
    box-shadow: -4px 8px 20px 0px rgba(0, 0, 0, 0.10)
    display: flex
    justify-content: space-between

    +lte_ipad
      border-radius: rem4(12px)
      padding: rem4(16px)

    +gte_laptop
      border-radius: 12px
      padding: 16px

    &:after
      +font-icon('\E82C')
      transform: rotate(180deg)

      +lte_ipad
        font-size: rem4(18px)

      +gte_laptop
        font-size: 18px

    &:hover
      text-decoration: none

  .guide
    p
      +p3

      +lte_ipad
        margin-bottom: rem4(32px)

      +gte_laptop
        margin-bottom: 32px

    .what-fits
      +p4(700)

      +lte_ipad
        margin-bottom: rem4(12px)

      +gte_laptop
        margin-bottom: 12px

  // copied from guide-modal ul
  $image_path: '../images/vue/storage_schema_boxes/guide_modal'
  ul.what-fits-types
    display: flex
    flex-wrap: wrap

    +lte_ipad
      gap: rem4(12px)

    +gte_laptop
      gap: 12px

    li
      +p4
      align-items: center
      background: var(--gray-100)
      border-radius: 100px
      display: flex
      justify-content: center

      +lte_ipad
        gap: rem4(8px)
        padding: rem4(4px) rem4(8px)

      +gte_laptop
        gap: 8px
        padding: 4px 8px

      &:before
        align-items: center
        background-position: center
        background-repeat: no-repeat
        background-size: cover
        display: flex
        flex-shrink: 0
        justify-content: center

        +lte_ipad
          height: rem4(20px)
          width: rem4(20px)

        +gte_laptop
          height: 20px
          width: 20px

      @each $type in bicycle, broom, cabinet, clothes, construction, furniture, goods, skates, sofa, tent, tree, work
        &.#{$type}:before
          content: ''
          background-image: url('#{$image_path}/what_fits/#{$type}.svg')

      // из figma не экспортируется нормально svg, поэтому сделал png
      &.chair:before
        content: ''
        background-image: url('#{$image_path}/what_fits/chair@2x.png')

  .features
    .features-wrapper
      +lte_ipad
        padding-bottom: rem4(16px)
        margin-bottom: rem4(-16px)
        overflow-x: scroll

    ul.b-features_v2
      &.is-mobile
        +gte_laptop
          display: none

      &.is-desktop
        +lte_ipad
          display: none

        +gte_laptop
          grid-template-columns: 1fr 1fr

  article.slide_2
    +container

    .b-map
      border-radius: rem4(24px)
      box-shadow: -4px 8px 20px 0px rgba(0, 0, 0, 0.10)
      overflow: hidden
